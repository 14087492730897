<template>
  <div>
    <p class="text-2xl mb-6">Liste des 'event types'</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn color="primary" link :to="{ name: 'rmra-event-types-create' }">
        <v-icon class="mr-3">{{ icons.mdiPlusBox }}</v-icon
        >Créer un nouvel 'event type'</v-btn
      >
    </v-col>
    <v-col cols="12"> <event-types-table></event-types-table> </v-col>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import EventTypesTable from "@/components/rmr-analytics/tables/EventTypesTable.vue";

export default {
  name: "EventTypesList",
  components: {
    EventTypesTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
